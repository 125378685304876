import { useMutation, useQuery } from "@tanstack/react-query";
import { createSport, getSports } from "modules/api";
import { ResponseSports, Sports, SportsParams } from "../types";
import { useState } from "react";
import { AxiosResponse } from "axios";
import { notifications } from "@mantine/notifications";
import { clearSession } from "services/store";

interface Props {
  refetch: () => void;
}

export function useCreateSport(props: Props) {
  const { refetch } = props;
  // const { reFetch } = props;
  //   const [sports, setSports] = useState<ResponseSports>();
  const [isSuccess, setIsSuccess] = useState(false);
  //   const [error, setError] = useState<string>("");

  const { mutate: create, isPending: isLoading } = useMutation({
    mutationFn: (data: any) => createSport(data),
    retry: false,
    onSuccess: (res: AxiosResponse) => {
      setIsSuccess(true);

      refetch();
      //   setError("");
      //   const data = res?.data?.data;
      //   setSports(data);
      console.log("🚀 ~ useSports ~ data:", res);
    },
    onError: (e: any) => {
      notifications.show({
        title: `${e?.data?.message}`,
        message: null,
        color: "red",
      });
      //   setError("Internal server error");
      const statusCode: number = e?.status;
      // statusCode === 401 && clearSession();
      console.log("🚀 ~ useSports ~ e:", e);
    },
  });

  return { isSuccess, isLoading, create };
}
