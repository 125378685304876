import { notifications } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { createSportMen } from "modules/api";
import { clearSession } from "services/store";

export function useCreateSportmen(onCloseModal: () => void) {
  const queryClient = useQueryClient();
  const { mutate: create, isPending: isPendingCreate } = useMutation({
    mutationFn: (data: any) => createSportMen(data),
    retry: false,
    onSuccess: (res: AxiosResponse) => {
      console.log("🚀 ~ useSports ~ data:", res);
      queryClient.invalidateQueries({ queryKey: ["sportmen"] });
      onCloseModal();
    },
    onError: (e: any) => {
      notifications.show({
        title: `Serverda xatolik`,
        message: null,
        color: "red",
      });
      const statusCode: number = e?.status;
      // statusCode === 401 && clearSession();
      //   setError("Internal server error");
      console.log("🚀 ~ useSports ~ e:", e);
    },
  });

  return { isPendingCreate, create };
}
