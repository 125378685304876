import { useQuery } from "@tanstack/react-query";
import { getRegions, getSportsSelect } from "modules/api";

export function useSportsSelect() {
  const {
    isPending,
    data: sportsSelect,
    error,
    isError,
    status,
  } = useQuery({
    queryKey: ["sportsSelect"],
    queryFn: getSportsSelect,
    retry: false,
  });

  return { status, isError, isPending, error, sportsSelect };
}
