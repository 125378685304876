import { clearSession } from "services/store";
import { IEntity } from "modules/auth/types";
import { LOGIN, LOG_OUT, SET_USER_DATA } from "../../actionTypes";

export type AuthState = {
  isAuth: Boolean;
  userData: IEntity.User | null;
};

interface SetUserDataAction {
  type: typeof SET_USER_DATA;
  payload: {
    isAuth: boolean;
    data: IEntity.User;
  };
}
interface LogOutAction extends SetUserDataAction {
  type: typeof LOG_OUT;
}

type LocalAction = SetUserDataAction | LogOutAction;

const initialState: AuthState = {
  isAuth: false,
  userData: null,
};

export const authReducer = (
  state: AuthState = initialState,
  action: LocalAction
) => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        isAuth: action.payload.isAuth,
      };
    }
    case SET_USER_DATA: {
      return {
        isAuth: action.payload.isAuth,
        userData: action?.payload?.data,
      };
    }
    case LOG_OUT: {
      clearSession();
      return initialState;
    }

    default:
      return { ...state };
  }
};
