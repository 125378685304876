import React, { FC } from "react";

interface CustomComponentProps {
  Component: React.ComponentType<any>;
  [key: string]: any; // Additional props
}

const CustomComponent: FC<CustomComponentProps> = ({ Component, ...props }) => {
  return <Component {...props} />;
};

export default CustomComponent;
