export function sportTypeConverter(type: string) {
  switch (type) {
    case "SPORT_OYINLARI":
      return "Sport o'yinlari";

    case "YAKKA_KURASH_SPORT_TURLARI":
      return "Yakka kurash sport turlari";

    case "QIYIN_MUVOFIQLASHTIRUVCHI_SPORT_TURLARI":
      return "Qiyin muvofiqlashtiruvchi sport turlari";

    case "KUCHLI_SPORT_TURLARI":
      return "Kuchli sport turlari";

    case "SIKLIK_SPORT_TURLARI":
      return "Siklik sport turlari";

    default:
      break;
  }
}
