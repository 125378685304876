import { Avatar, Button, Checkbox, Flex, Table, Text } from "@mantine/core";
import config from "config";
import dayjs from "dayjs";
import { ResponseSports, Sports } from "modules/sports/types";
import React, { useState } from "react";
import { sportTypeConverter } from "utils/sportTypeConverter";

const elements = [
  { position: 6, symbol: "C", name: "Carbon" },
  { position: 7, symbol: "N", name: "Nitrogen" },
  { position: 39, symbol: "Y", name: "Yttrium" },
  { position: 56, symbol: "Ba", name: "Barium" },
  { position: 58, symbol: "Ce", name: "Cerium" },
];

interface Props {
  sports: Sports[] | undefined;
  handleEdit: (id: number) => void;
  handleDelete: (id: number) => void;
}

const TableSports = (props: Props) => {
  const { sports, handleEdit, handleDelete } = props;

  const rows = sports?.map((element) => (
    <Table.Tr key={element.id}>
      <Table.Td>
        <Avatar
          src={
            element?.photoPath
              ? `${config.api.baseURL}/storage/files/${element.photoPath}`
              : null
          }
          className="cursor-pointer"
          size={"md"}
        />
      </Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>
        <Text className="truncate w-[120px] overflow-hidden">
          {element.shortDescription}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text className="truncate w-[150px] overflow-hidden">
          {element.description}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text>{dayjs(element.createdAt).format("DD-MM-YYYY / HH:mm:ss")}</Text>
      </Table.Td>
      <Table.Td>
        <Text className="">{sportTypeConverter(element.type)}</Text>
      </Table.Td>
      <Table.Td>
        <Flex justify={"end"}>
          <Button
            mr={10}
            variant="light"
            onClick={() => handleEdit(element.id)}
          >
            Tahrirlash
          </Button>
          <Button
            variant="light"
            color="red"
            onClick={() => handleDelete(element.id)}
          >
            O'chirish
          </Button>
        </Flex>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Table
      withColumnBorders
      horizontalSpacing={"md"}
      striped
      highlightOnHover
      withTableBorder
    >
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Rasm</Table.Th>
          <Table.Th>Nomi</Table.Th>
          <Table.Th>Qisqa izoh</Table.Th>
          <Table.Th>Izoh</Table.Th>
          <Table.Th>Yaratilgan vaqti</Table.Th>
          <Table.Th>Turi</Table.Th>
          <Table.Th className="text-center" />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
};

export default TableSports;
