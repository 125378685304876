import React, { useState } from "react";

const TestComponent = () => {
  const [age, setAge] = useState<number | string>("");
  const [previousAge, setPreviousAge] = useState<number | string>("");

  const onChangeAge = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAge(value);
  };

  const showResult = () => {
    setPreviousAge(age.toString());
  };
  return (
    <div>
      <input
        value={age}
        type="number"
        placeholder="Please enter your age"
        onChange={onChangeAge}
      />

      <button onClick={showResult}>Show result</button>

      {previousAge && (
        <div>
          <h1>Previous age value is: {previousAge}</h1>
          <h1>Current age value is: {age}</h1>
        </div>
      )}
    </div>
  );
};

export default TestComponent;
