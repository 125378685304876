import {
  ActionIcon,
  Button,
  Collapse,
  Flex,
  LoadingOverlay,
  Select,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import DashboardTable from "components/DashboardSprTable";
import { Option } from "components/RegionDistrictSelect";
import SportsSelect from "components/SportsSelect";
import { useRegions } from "hooks/useRegions";
import { RotateCcw, Search, SlidersHorizontal } from "lucide-react";
import { getStsBySport } from "modules/api";
import React, { Fragment, useEffect, useState } from "react";

interface Region {
  name: string;
  id: string;
}

export interface SportStatis {
  result: ResultSport[];
  name: string;
}

export interface ResultSport {
  testName: string;
  avrResult: string;
  maxResult: string;
  minResult: string;
}
interface Params {
  classNumber: string;
  regionId: string | null;
  sportId: string | null;
}

const TabSport = () => {
  const [opened, { toggle }] = useDisclosure(true);
  const [valueRegion, setValueRegion] = useState<string | null>();
  const [sportStatistics, setSportStatistics] = useState<SportStatis[]>([]);
  const [loading, setLoading] = useState(false);
  const [valueSport, setValueSport] = useState<string | null>();
  const [params, setParams] = useState<Params>({
    classNumber: "5",
    regionId: null,
    sportId: null,
  });
  const { status, isError, error, isPending, regions } = useRegions();
  const data = regions?.data?.data;
  const form = useForm({
    mode: "controlled",
    initialValues: {
      classNumber: "5",
      sportId: "",
      regionId: "",
    },

    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });
  const convertedData: Option[] = data?.length
    ? data.map((item: Region) => {
        return {
          value: item.id.toString(),
          label: item.name,
        };
      })
    : null;

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getStsBySport(params);
        const data: SportStatis[] = res?.data?.data;
        setSportStatistics(data);
        // console.log("🚀 ~ data:", data);
      } catch (error: any) {
        console.log("🚀 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [params]);

  const onSubmitFilters = (values: any) => {
    setParams(values);
    console.log("🚀 ~ onSubmitFilters ~ values:", values);
  };

  const onResetFilters = () => {
    form.reset();
    setValueRegion(null);
    setValueSport(null);
    setParams({
      classNumber: "5",
      regionId: null,
      sportId: null,
    });
  };

  const handleChangeReg = (options: Option) => {
    if (options) {
      form.setFieldValue("regionId", options.value);
      setValueRegion(options.value);
    }
  };
  return (
    <Fragment>
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Flex justify={"start"} align={"center"}>
        <Button
          rightSection={<SlidersHorizontal size={14} />}
          variant="default"
          onClick={toggle}
        >
          Filtrlar
        </Button>
      </Flex>

      <Collapse in={opened}>
        <form
          className="pt-3"
          onSubmit={form.onSubmit((values) => onSubmitFilters(values))}
        >
          <Flex align={"end"} justify={"space-between"}>
            <Flex>
              {/* Class num select */}
              {/* Sport type */}
              {/* Viloyat */}
              <Select
                {...form.getInputProps("classNumber")}
                allowDeselect={false}
                label="Sinf"
                className="mr-2"
                defaultValue={"5"}
                data={[
                  { value: "5", label: "5-Sinf" },
                  { value: "6", label: "6-Sinf" },
                  { value: "7", label: "7-Sinf" },
                  { value: "8", label: "8-Sinf" },
                  { value: "9", label: "9-Sinf" },
                  { value: "10", label: "10-Sinf" },
                  { value: "11", label: "11-Sinf" },
                ]}
              />
              <SportsSelect
                setValueSport={setValueSport}
                valueSport={valueSport}
                form={form}
              />
              <Select
                className="ml-2"
                {...(form.getInputProps("regionId"),
                {
                  onChange: (newValue, options) => handleChangeReg(options),
                  error: form.errors.regionId,
                  value: valueRegion,
                })}
                label={"Viloyat"}
                placeholder="Iltimos, viloyatni tanlang"
                data={convertedData}
                // value={value}
                // onChange={(newValue, options) => handleChangeReg(options)}
              />
            </Flex>
            <Flex align={"center"}>
              <Button
                className="mx-2"
                type="submit"
                rightSection={<Search size={14} />}
              >
                Qidirsh
              </Button>
              <ActionIcon
                onClick={onResetFilters}
                size={"input-sm"}
                variant="filled"
                aria-label="Settings"
              >
                <RotateCcw size={14} />
              </ActionIcon>
            </Flex>
          </Flex>
        </form>
      </Collapse>

      {sportStatistics?.length ? (
        <DashboardTable sportStatistics={sportStatistics} />
      ) : (
        <Text className="px-5 py-5 text-center">Xozrcha ma'lumot yo'q</Text>
      )}
    </Fragment>
  );
};

export default TabSport;
