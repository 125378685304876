import { Box, Button, Flex, Group, Modal, Text, rem } from "@mantine/core";
import {
  Dropzone,
  FileRejection,
  FileWithPath,
  MIME_TYPES,
} from "@mantine/dropzone";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import RegionDistrictSelect from "components/RegionDistrictSelect";
import { useUploadExcel } from "hooks/useUploadExcel";
import { FileSpreadsheet, UploadIcon, X } from "lucide-react";
import React, { useState } from "react";
import { formatFileSize } from "utils/FormatFileSize";

interface Props {
  openedUpload: boolean;
  setOpenedUpload: any;
}

const UploadExcelModal = (props: Props) => {
  const { openedUpload, setOpenedUpload } = props;
  const { isUpload, upload } = useUploadExcel({ reFetch: onCloseUpload });
  const [valueRegion, setValueRegion] = useState<string | null>();
  const [file, setFile] = useState<FileWithPath | null>(null);
  const formUploadExcel = useForm({
    mode: "uncontrolled",
    initialValues: {
      regionId: "",
      districtId: "",
    },

    validate: {
      regionId: isNotEmpty("Please select region"),
      // district_id: isNotEmpty("Please select region"),
    },
  });

  const onSubmitUpload = (values: { regionId: string; districtId: string }) => {
    if (file) {
      const data = {
        regionId: values.regionId,
        districtId: values.districtId,
        excel: file,
      };
      upload(data);
    } else {
      notifications.show({
        title: `Iltimos, excel faylini yuklang`,
        message: null,
        color: "red",
      });
    }
  };

  const onDrop = (file: FileWithPath[]) => {
    setFile(file[0]);
  };
  const onReject = (file: FileRejection[]) => {
    const fileSizeInBytes: number = file[0]?.file.size;
    const formattedSize: string = formatFileSize(fileSizeInBytes);

    // File is larger than 10485760 bytes
    notifications.show({
      title: `Fayl 10 mb dan katta`,
      message: "10 mb gacha yuklash mumkin",
      color: "red",
    });
  };

  function onCloseUpload() {
    setOpenedUpload(false);
    setFile(null);
    formUploadExcel.reset();
  }

  return (
    <Modal.Root size={"xl"} opened={openedUpload} onClose={onCloseUpload}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Excel yuklash</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Box>
            <form
              onSubmit={formUploadExcel.onSubmit((values) =>
                onSubmitUpload(values)
              )}
            >
              <Flex
                mb={20}
                gap="md"
                justify="flex-start"
                align="flex-start"
                direction="row"
                // wrap="wrap"
              >
                <RegionDistrictSelect
                  valueRegion={valueRegion}
                  setValueRegion={setValueRegion}
                  classesRegion="w-full"
                  classesDistr="w-full"
                  form={formUploadExcel}
                />
                {/* <Select
              label={"Region"}
              className="w-full"
              placeholder="Please select region"
              data={[{ value: "react", label: "React library" }]}
            /> */}
                {/* <Select
              label={"District"}
              className="w-full"
              placeholder="Please select district"
              data={[{ value: "react", label: "React library" }]}
              value={value ? value.value : null}
              onChange={(_value, option) => setValue(option)}
            /> */}
              </Flex>

              <Text size="sm">Excel yuklash</Text>
              <Dropzone
                // disabled
                onDrop={(file) => onDrop(file)}
                onReject={(file) => onReject(file)}
                maxSize={15 * 1024 ** 2}
                accept={[MIME_TYPES.xlsx]}
              >
                <Group
                  justify="center"
                  // gap="xl"
                  mih={50}
                  style={{ pointerEvents: "none" }}
                >
                  <Dropzone.Accept>
                    <UploadIcon
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-blue-6)",
                      }}
                    />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <X
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-red-6)",
                      }}
                    />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <FileSpreadsheet
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-dimmed)",
                      }}
                    />
                  </Dropzone.Idle>

                  <div>
                    <Text size="sm" inline>
                      Excel faylini bu yerga torting yoki excel fayllarini
                      tanlash uchun bosing
                    </Text>
                    <Text size="sm" c="dimmed" inline mt={7}>
                      Excel fayli 5 mb dan oshmasligi kerak
                    </Text>
                  </div>
                </Group>
              </Dropzone>
              {file && (
                <Box mt={20}>
                  <Button
                    color="green"
                    justify="space-between"
                    fullWidth
                    rightSection={<X onClick={() => setFile(null)} size={14} />}
                    variant="light"
                  >
                    {file.name}
                  </Button>
                </Box>
              )}

              <Flex justify={"end"} mt={20}>
                <Box>
                  <Button variant="default" mr={10} onClick={onCloseUpload}>
                    Yopish
                  </Button>
                  <Button loading={isUpload} className="mx-2" type="submit">
                    Yuklash
                  </Button>
                </Box>
              </Flex>
            </form>
          </Box>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default UploadExcelModal;
