import React, { Fragment, useEffect, useState } from "react";
import { Select } from "@mantine/core";
import { useRegions } from "hooks/useRegions";
import { useDistrict } from "hooks/useDistrict";
import { notifications } from "@mantine/notifications";
import { FormProps } from "@mantine/form/lib/Form/Form";

export interface Option {
  value: string;
  label: string;
}

interface Region {
  name: string;
  id: string;
}

interface Props {
  form: any;
  classesRegion?: string;
  classesDistr?: string;
  valueRegion: any;
  setValueRegion: any;
}

const RegionDistrictSelect = (props: Props) => {
  const { form, classesRegion, classesDistr, setValueRegion, valueRegion } =
    props;

  const { districts, getDistrict, isPendingDis } = useDistrict();
  const { status, isError, error, isPending, regions } = useRegions();
  const data = regions?.data?.data;

  const convertedData: Option[] = data?.length
    ? data.map((item: Region) => {
        return {
          value: item.id.toString(),
          label: item.name,
        };
      })
    : null;

  const handleChangeReg = (options: Option) => {
    if (options) {
      form.setFieldValue("regionId", options.value);
      setValueRegion(options.value);
      getDistrict(options?.value);
    }
  };
  const handleChangeDis = (options: Option) => {
    if (options) {
      setValueRegion(options.value);
      getDistrict(options?.value);
    }
  };

  // if (status === "pending") {
  //   return <span>Loading...</span>;
  // }

  // if (status === "error") {
  //   return <span>Error</span>;
  // }

  return (
    <Fragment>
      <Select
        {...(form.getInputProps("regionId"),
        {
          onChange: (newValue, options) => handleChangeReg(options),
          error: form.errors.regionId,
          value: valueRegion,
        })}
        label={"Viloyat"}
        className={`${classesRegion ? classesRegion : ""}`}
        placeholder="Iltimos, viloyatni tanlang"
        data={convertedData}
        // value={value}
        // onChange={(newValue, options) => handleChangeReg(options)}
      />
      <Select
        {...form.getInputProps("districtId")}
        label={"Tuman"}
        className={`${classesDistr ? classesDistr : ""}`}
        placeholder="Iltimos, tumanni tanlang"
        data={districts}
        // value={value}
        // onChange={(newValue, options) => handleChangeDis(options)}
      />
    </Fragment>
  );
};

export default RegionDistrictSelect;
