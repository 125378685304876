import { notifications } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";
import { Option } from "components/RegionDistrictSelect";
import { getDistricts, postUpdateToken } from "modules/api";
import { useState } from "react";
import { setSessionAccess } from "services/store";

interface District {
  name: string;
  id: string;
}

export function useUpdateAccess() {
  const { status, mutate: updateToken } = useMutation({
    mutationFn: postUpdateToken,
    onSuccess(data) {
      const res = data?.data?.data;
      setSessionAccess(res?.accessToken);
      console.log("🚀 ~ onSuccess ~ res:", res);
    },
    onError(error, variables, context) {
      notifications.show({
        title: `Get district failed`,
        message: null,
        color: "red",
      });
    },
    retry: false,
  });

  return { status, updateToken };
}
