import { Box, Tabs } from "@mantine/core";
import { Settings } from "lucide-react";
import { Fragment } from "react";
import TabSport from "./Tabs/TabSport";
import TabRegion from "./Tabs/TabRegion";
import TabsHeightSport from "./Tabs/TabsHeightSport";
import TabsHeightReg from "./Tabs/TabsHeightReg";

const Dashboard = () => {
  return (
    <Fragment>
      <Tabs defaultValue="gallery">
        <Tabs.List grow>
          <Tabs.Tab value="gallery" leftSection={<Settings size={14} />}>
            Sport
          </Tabs.Tab>
          <Tabs.Tab value="messages" leftSection={<Settings size={14} />}>
            Viloyat
          </Tabs.Tab>
          <Tabs.Tab value="settings" leftSection={<Settings size={14} />}>
            Bo'yi (sport)
          </Tabs.Tab>
          <Tabs.Tab value="region" leftSection={<Settings size={14} />}>
            Bo'yi (viloyat)
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="gallery">
          <Box mt={20}>
            <TabSport />
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="messages">
          <Box mt={20}>
            <TabRegion />
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="settings">
          <Box mt={20}>
            <TabsHeightSport />
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="region">
          <Box mt={20}>
            <TabsHeightReg />
          </Box>
        </Tabs.Panel>
      </Tabs>
    </Fragment>
  );
};

export default Dashboard;
