import { Select } from "@mantine/core";
import { useSportsSelect } from "hooks/useSportsSelect";
import { Fragment } from "react";

export interface Option {
  value: string;
  label: string;
}

interface Region {
  name: string;
  id: string;
}

interface Props {
  form: any;
  classesRegion?: string;
  classesDistr?: string;
  valueSport: any;
  setValueSport: any;
}

const SportsSelect = (props: Props) => {
  const { form, classesRegion, classesDistr, valueSport, setValueSport } =
    props;

  const { status, isError, error, isPending, sportsSelect } = useSportsSelect();
  const data = sportsSelect?.data?.data;

  const convertedData: Option[] = data?.length
    ? data.map((item: Region) => {
        return {
          value: item.id.toString(),
          label: item.name,
        };
      })
    : null;

  const handleChangeReg = (options: Option) => {
    if (options) {
      form.setFieldValue("sportId", options.value);
      setValueSport(options.value);
    }
  };

  return (
    <Fragment>
      <Select
        {...(form.getInputProps("sportId"),
        {
          onChange: (newValue, options) => handleChangeReg(options),
          error: form.errors.sportId,
          value: valueSport,
        })}
        label={"Sport"}
        className={`${classesRegion ? classesRegion : ""}`}
        placeholder="Iltimos, sportni tanlang"
        data={convertedData}
      />
    </Fragment>
  );
};

export default SportsSelect;
