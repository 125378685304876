import { notifications } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";
import { Option } from "components/RegionDistrictSelect";
import { getDistricts } from "modules/api";
import { useState } from "react";
import { clearSession } from "services/store";

interface District {
  name: string;
  id: string;
}

export function useDistrict() {
  const [districts, setDistricts] = useState<any>();
  const { isPending: isPendingDis, mutate: getDistrict } = useMutation({
    mutationFn: (regionId: string) => getDistricts({ regionId }),
    onSuccess(data, variables, context) {
      const res = data?.data?.data;

      const convertedData: Option[] = res?.length
        ? res.map((item: District) => {
            return {
              value: item.id.toString(),
              label: item.name,
            };
          })
        : null;
      setDistricts(convertedData);
      // console.log("🚀 ~ onSuccess ~ convertedData:", convertedData);
    },
    onError(error: any, variables, context) {
      const statusCode: number = error?.status;
      // statusCode === 401 && clearSession();
      notifications.show({
        title: `Get district failed`,
        message: null,
        color: "red",
      });
    },
    retry: false,
  });

  return { districts, isPendingDis, getDistrict };
}
