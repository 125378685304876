import { notifications } from "@mantine/notifications";
import { downloadDoc } from "modules/api";
import { useState } from "react";

const useDownloadExcel = () => {
  const [dowloadingExcel, setDowloadingExcel] = useState<boolean>(false);

  const downloadExcel: () => void = async () => {
    try {
      setDowloadingExcel(true);
      const response = await downloadDoc();

      // Extract the filename from the Content-Disposition header
      const contentDisposition = response.headers?.["content-disposition"];

      // Original file name
      const fileName = contentDisposition
        ? contentDisposition?.split("=")?.[1]
        : "O'quvchi sportchilar uchun forma shakli.xlsx";

      // Create a Blob from the response data
      const pdfBlob = new Blob([response.data], {
        type: "application/vnd.ms-excel",
      });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(pdfBlob);

      // Create a temporary <a> element to trigger the download
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", fileName); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      notifications.show({
        title: `Download error`,
        message: null,
        color: "red",
      });
      console.log("🚀 ~ downloadExcelFun ~ error:", error);
    } finally {
      setDowloadingExcel(false);
    }
  };
  return { dowloadingExcel, downloadExcel };
};

export default useDownloadExcel;
