import { notifications } from "@mantine/notifications";
import axios, { AxiosResponse } from "axios";
import config from "config";

import {
  clearSession,
  getRefresh,
  getSession,
  setSessionAccess,
} from "./store";
import { postUpdateToken } from "modules/api";

export { AxiosError } from "axios";

const http = axios.create({ baseURL: config.api.baseURL });

http.interceptors.request.use(
  (request) => {
    const access = getSession();
    const refresh = getRefresh();

    // @ts-expect-error
    request.headers = {
      ...request.headers,
      ...(access ? { Authorization: `Bearer ${access}` } : {}),
      ...(refresh ? { ["Refresh-Token"]: `${refresh}` } : {}),
    };

    return request;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(null, (err) => {
  const statusCode: number = err?.response?.status;
  const response = err?.response || ({} as AxiosResponse);

  const { data } = response || {};

  if (statusCode === 401) {
    uodateToken();
  }

  if (data?.detail) notifications.show({ message: data?.detail, color: "red" });

  return Promise.reject(response);
});

async function uodateToken() {
  try {
    const res = await postUpdateToken();
    const data: any = res?.data?.data;
    setSessionAccess(data?.accessToken);
    window.location.reload();
    return Promise.resolve();
    // console.log("🚀 ~ uodateToken ~ data:", data);
  } catch (error: any) {
    window.location.href = "/";
    clearSession();
    // window.location.reload();
    console.log("🚀 ~ uodateToken ~ error:", error);
    // return Promise.reject();
  }
}

export default http;
