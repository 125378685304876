import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postLogin } from "../../api";
import { notifications } from "@mantine/notifications";
import { AxiosError, AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN, SET_USER_DATA } from "../../../redux/actionTypes";
import { useNavigate } from "react-router-dom";
import { setSessionAccess, setSessionRefresh } from "services/store";

export function useLogin() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutate: login, isPending: isLogin } = useMutation({
    mutationFn: postLogin,
    onSuccess: (res: AxiosResponse) => {
      const data: any = res?.data?.data;
      dispatch({
        type: LOGIN,
        payload: {
          isAuth: true,
        },
      });
      navigate("/");
      setSessionAccess(data?.accessToken);
      setSessionRefresh(data?.refreshToken);
    },
    onError: (err: any) => {
      console.log("🚀 ~ useLogin ~ err:", err);
      notifications.show({
        color: "red",
        title: err.data?.message,
        message: null,
      });
    },
  });

  return { login, isLogin };
}
