import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getSportmen } from "../../api";
import { ParamsGetSportmen } from "../types";

export function useSportmen(params: ParamsGetSportmen | undefined) {
  const {
    isLoading,
    data: sportmens,
    error,
    isPlaceholderData,
    status,
  }: any = useQuery({
    queryKey: ["sportmen", params],
    queryFn: () => getSportmen(params),
    retry: false,
    placeholderData: keepPreviousData,
  });

  return { status, isPlaceholderData, isLoading, error, sportmens };
}
