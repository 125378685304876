import http from "services/http";
import { IApi } from "./auth/types";
import { SportsParams } from "./sports/types";
import { RequestType } from "components/Modal";
import { ParamsGetSportmen } from "./sportmen/types";
import { FormValuesForecast } from "pages/Private/Forecast";

const postLogin = (data: IApi.Login.Request) => {
  return http({
    method: "POST",
    url: "/auth/login",
    data: data,
  });
};

const postUpdateToken = () => {
  return http({
    method: "POST",
    url: "/auth/refresh",
  });
};

const getAboutMe = () => {
  return http({
    method: "GET",
    url: "/auth/me",
  });
};
const getStsBySport = (params: any) => {
  return http({
    method: "GET",
    url: "/statistic/by-sport",
    params,
  });
};

const getStsByRegion = (params: any) => {
  return http({
    method: "GET",
    url: "/statistic/by-region",
    params,
  });
};

const getStsByHeigSport = (params: any) => {
  return http({
    method: "GET",
    url: "/statistic/height-by-sport",
    params,
  });
};

const getStsByHeigReg = (params: any) => {
  return http({
    method: "GET",
    url: "/statistic/height-by-region",
    params,
  });
};

const getTestByclass = (params: { classNumber: string }) => {
  return http({
    method: "GET",
    url: "/admin/test/by-classNumber",
    params,
  });
};

const getRegions = () => {
  return http({
    method: "GET",
    url: "/region/select",
  });
};

const deleteSportmen = (id: number) => {
  return http({
    method: "DELETE",
    url: `/admin/disciple/${id}`,
  });
};

const getDistricts = (params: { regionId: string }) => {
  return http({
    method: "GET",
    url: "/district/select",
    params,
  });
};

const getSportsSelect = () => {
  return http({
    method: "GET",
    url: "/sport/select",
  });
};

const downloadDoc = () => {
  return http({
    method: "GET",
    url: "/admin/disciple/download-excel-form",
    responseType: "blob",
  });
};

const uploadDoc = (data: any) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return http({
    method: "POST",
    url: "/admin/disciple/upload-excel",
    data: formData,
  });
};

const getSports = (params: SportsParams) => {
  return http({
    method: "GET",
    url: `/admin/sport`,
    params,
  });
};

const getArticles = (params: SportsParams) => {
  return http({
    method: "GET",
    url: `/admin/article`,
    params,
  });
};

const getSportById = (id: number | null) => {
  return http({
    method: "GET",
    url: `/admin/sport/${id}`,
  });
};

const getArticleById = (id: number | null) => {
  return http({
    method: "GET",
    url: `/admin/article/${id}`,
  });
};

const getSportmenById = (id: number | null) => {
  return http({
    method: "GET",
    url: `/admin/disciple/${id}`,
  });
};

const deleteSport = (id: number | null) => {
  return http({
    method: "DELETE",
    url: `/admin/sport/${id}`,
  });
};

const deleteArticle = (id: number | null) => {
  return http({
    method: "DELETE",
    url: `/admin/article/${id}`,
  });
};

const createSport = (data: any) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return http({
    method: "POST",
    url: `/admin/sport/create`,
    data: formData,
  });
};

const createArticle = (data: any) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return http({
    method: "POST",
    url: `/admin/article/create`,
    data: formData,
  });
};

const createSportMen = (data: any) => {
  return http({
    method: "POST",
    url: `/admin/disciple/create`,
    data,
  });
};

const editSport = (data: any, id: number | null) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return http({
    method: "PUT",
    url: `/admin/sport/update/${id}`,
    data: formData,
  });
};
const editArticle = (data: any, id: number | null) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return http({
    method: "PUT",
    url: `/admin/article/update/${id}`,
    data: formData,
  });
};

const editSportmen = (data: any, id?: number | null) => {
  return http({
    method: "PUT",
    url: `/admin/disciple/update/${id}`,
    data: data,
  });
};

// Sportmen
const getSportmen = (params: ParamsGetSportmen | undefined) => {
  return http({
    method: "GET",
    url: "/admin/disciple",
    params,
  });
};

const getForecast = () => {
  return http({
    method: "GET",
    url: "/forecast/calculate",
  });
};

const getAllForecasts = () => {
  return http({
    method: "GET",
    url: "/admin/forecast",
  });
};

const createForecast = (data: FormValuesForecast) => {
  return http({
    method: "POST",
    url: "/admin/forecast/create",
    data,
  });
};
const updateForecast = (data: FormValuesForecast, id: string) => {
  return http({
    method: "PUT",
    url: `/admin/forecast/update/${id}`,
    data,
  });
};

export {
  getStsByHeigReg,
  getStsByHeigSport,
  getStsByRegion,
  getStsBySport,
  deleteArticle,
  createArticle,
  editArticle,
  getArticleById,
  getArticles,
  updateForecast,
  createForecast,
  getAllForecasts,
  deleteSportmen,
  getTestByclass,
  postUpdateToken,
  getSportmenById,
  editSportmen,
  createSportMen,
  getForecast,
  getSportsSelect,
  getSportmen,
  getDistricts,
  getRegions,
  uploadDoc,
  downloadDoc,
  deleteSport,
  editSport,
  getSportById,
  createSport,
  getSports,
  postLogin,
  getAboutMe,
};
