import { notifications } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { uploadDoc } from "modules/api";
import React from "react";

export const useUploadExcel = ({ reFetch }: any) => {
  const { mutate: upload, isPending: isUpload } = useMutation({
    mutationFn: uploadDoc,
    onSuccess: (res: AxiosResponse) => {
      reFetch();
      const data: any = res?.data?.data;
      notifications.show({
        title: "Excel fayli muvaffaqiyatli yuklandi",
        message: null,
      });
      console.log("🚀 ~ useUploadExcel ~ data:", data);
    },
    onError: (err: any) => {
      console.log("🚀 ~ useLogin ~ err:", err);
      notifications.show({
        color: "red",
        title: err.data?.message,
        message: null,
      });
    },
  });

  return { upload, isUpload };
};
