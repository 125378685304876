import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  Flex,
  LoadingOverlay,
  Pagination,
  Text,
  TextInput,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import CreateSportmenModal from "components/CreateSportmenModal";
import RegionDistrictSelect from "components/RegionDistrictSelect";
import SportsSelect from "components/SportsSelect";
import TableSportmen from "components/TableSportmen";
import UploadExcelModal from "components/UploadExcelModal";
import useDownloadExcel from "hooks/useDownloadExcel";
import {
  FileText,
  Plus,
  RotateCcw,
  Search,
  SlidersHorizontal,
  Upload,
} from "lucide-react";
import { deleteSportmen } from "modules/api";
import { useSportmen } from "modules/sportmen/hooks/useSportmen";
import { DataSportmen, ParamsGetSportmen } from "modules/sportmen/types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clearSession } from "services/store";
import { isNotEmpty } from "utils/isNotEmpty";

const Sportmen = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { dowloadingExcel, downloadExcel } = useDownloadExcel();
  const [openedUpload, setOpenedUpload] = useState(false);
  const [openedCreate, setOpenedCreate] = useState(false);
  const [opened, { toggle }] = useDisclosure(false);
  const [sportmenId, setSportmenId] = useState<number | null>(null);
  const [valueRegion, setValueRegion] = useState<string | null>();
  const [valueSport, setValueSport] = useState<string | null>();
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      from: null,
      to: null,
      regionId: null,
      districtId: null,
      sportId: null,
      search: null,
    },

    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });
  const [params, setParams] = useState<any>();
  const { status, isPlaceholderData, error, isLoading, sportmens } =
    useSportmen(params);
  const data: DataSportmen = sportmens?.data?.data;

  const onSubmitFilters = (values: ParamsGetSportmen) => {
    if (isNotEmpty(values)) {
      setParams(values);
      console.log("🚀 ~ onSubmitFilters ~ values:", values);
      // queryClient.invalidateQueries({ queryKey: ["sportmen"] });
    } else {
      notifications.show({
        title: `Please enter any value`,
        message: null,
        color: "yellow",
      });
    }
  };

  const onResetFilters = () => {
    form.reset();
    setParams(undefined);
    setValueRegion(null);
    setValueSport(null);
  };

  const handlePage = (page: any) => {
    setParams((prevParams: any) => ({
      ...prevParams,
      page,
    }));
  };

  useEffect(() => {
    if (status === "error") {
      if (error?.status === 401) {
        clearSession();
        navigate("/");
      } else {
        notifications.show({
          title: `Xatolik yuz berdi`,
          message: null,
          color: "red",
        });
      }
    }
  }, []);

  const openEditModal = (id: number) => {
    setSportmenId(id);
    setOpenedCreate(true);
  };

  const openDeleteModal = (id: number) => {
    modals.openConfirmModal({
      title: "Ushbu sportchini o'chiqmoqchimisiz",
      labels: { confirm: "Xa", cancel: "Yo'q" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => {
        try {
          const res = await deleteSportmen(id);
          notifications.show({
            title: "Sportchi muvaffaqtiyatli o'chirildi",
            message: null,
          });
          queryClient.invalidateQueries({ queryKey: ["sportmen"] });
        } catch (error: any) {
          console.log("🚀 ~ onConfirm:async ~ error:", error);
        }
      },
    });
  };

  return (
    <div className="h-full">
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <LoadingOverlay
        visible={isPlaceholderData}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Flex justify={"space-between"} align={"center"}>
        <Button
          rightSection={<SlidersHorizontal size={14} />}
          variant="default"
          onClick={toggle}
        >
          Filtrlar
        </Button>
        <Box>
          <Button
            variant="default"
            onClick={() => setOpenedUpload(true)}
            rightSection={<Upload size={14} />}
            mr={10}
          >
            Excel yuklash
          </Button>
          <Button
            mr={10}
            onClick={downloadExcel}
            // color="green"
            variant="default"
            loading={dowloadingExcel}
            rightSection={<FileText size={14} />}
          >
            Shablonni yuklab olish
          </Button>
          <Button
            rightSection={<Plus size={14} />}
            onClick={() => {
              setSportmenId(null);
              setOpenedCreate(true);
            }}
          >
            Yaratish
          </Button>
        </Box>
      </Flex>

      <Collapse in={opened}>
        <form
          className="pt-3"
          onSubmit={form.onSubmit((values) => onSubmitFilters(values))}
        >
          <Flex align={"end"} justify={"space-between"}>
            <Flex>
              <DateInput
                // value={value}
                // onChange={setValue}
                className="mr-2"
                {...form.getInputProps("from")}
                label="Qachondan"
                placeholder="Sanani kiriting"
              />
              <DateInput
                // value={value}
                // onChange={setValue}
                className="mr-2"
                {...form.getInputProps("to")}
                label="Qachongacha"
                placeholder="Sanani kiriting"
              />
              <RegionDistrictSelect
                valueRegion={valueRegion}
                setValueRegion={setValueRegion}
                classesRegion="mr-2"
                classesDistr="mr-2"
                form={form}
              />
              <SportsSelect
                form={form}
                setValueSport={setValueSport}
                valueSport={valueSport}
              />
              <TextInput
                className="ml-2"
                label="Qidirish"
                placeholder="Iltimos, qidiruv so'zini kiriting"
                {...form.getInputProps("search")}
              />
            </Flex>
            <Flex align={"center"}>
              <Button
                className="mx-2"
                type="submit"
                rightSection={<Search size={14} />}
              >
                Qidirsh
              </Button>
              <ActionIcon
                onClick={onResetFilters}
                size={"input-sm"}
                variant="filled"
                aria-label="Settings"
              >
                <RotateCcw size={14} />
              </ActionIcon>
            </Flex>
          </Flex>
        </form>
      </Collapse>

      <UploadExcelModal
        openedUpload={openedUpload}
        setOpenedUpload={setOpenedUpload}
      />

      <CreateSportmenModal
        setSportmenId={setSportmenId}
        sportmenId={sportmenId}
        openedCreate={openedCreate}
        setOpenedCreate={setOpenedCreate}
      />

      <TableSportmen
        data={data?.items}
        openDeleteModal={openDeleteModal}
        openEditModal={openEditModal}
      />

      {data?.items?.length ? (
        <Flex justify={"center"} mt={20}>
          <Pagination
            onChange={handlePage}
            value={data.meta?.page}
            total={data.meta?.totalPage}
          />
        </Flex>
      ) : (
        ""
      )}
    </div>
  );
};

export default Sportmen;
