import { notifications } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { editSportmen } from "modules/api";
import { clearSession } from "services/store";

export function useEditSportmen(onCloseModal: () => void, id: any) {
  const queryClient = useQueryClient();
  const { mutate: update, isPending: isPendingUpdate } = useMutation({
    mutationFn: (data: any) => {
      // console.log("🚀 ~ useEditSportmen ~ data:", data);
      return editSportmen(data, id);
    },
    retry: false,
    onSuccess: (res: AxiosResponse) => {
      onCloseModal();
      queryClient.invalidateQueries({ queryKey: ["sportmen"] });
      console.log("🚀 ~ useSports ~ data:", res);
    },
    onError: (e: any) => {
      notifications.show({
        title: `Serverda xatolik`,
        message: null,
        color: "red",
      });
      const statusCode: number = e?.status;
      // statusCode === 401 && clearSession();
      //   setError("Internal server error");
      console.log("🚀 ~ useSports ~ e:", e);
    },
  });

  return { update, isPendingUpdate };
}
