import {
  Box,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  NumberInput,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { Plus } from "lucide-react";
import { createForecast, getAllForecasts, updateForecast } from "modules/api";
import { useEffect, useState } from "react";

interface Forecasts {
  id: number;
  age: number;
  percentForecast: number;
  createdAt: string;
  updateAt: string;
}
export interface FormValuesForecast {
  age: string;
  percentForecast: string;
}

const Forecast = () => {
  const [data, setData] = useState<Forecasts[]>([]);
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [trigger, setTrigger] = useState(false);
  const [id, setId] = useState<string>("");
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      age: "",
      percentForecast: "",
    },

    validate: {
      age: isNotEmpty(""),
      percentForecast: isNotEmpty(""),
    },
  });

  // Get data
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getAllForecasts();
        const data: Forecasts[] = res?.data?.data;
        setData(data);
        console.log("🚀 ~ data:", data);
      } catch (error: any) {
        console.log("🚀 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [trigger]);

  // Edit handler
  const onEdit = (id: number, element: Forecasts) => {
    setId(id.toString());
    form.setValues({
      age: element.age.toString(),
      percentForecast: element.percentForecast.toString(),
    });
    open();
  };

  // Table rows
  const rows = data?.length ? (
    data.map((element) => (
      <Table.Tr key={element.id}>
        <Table.Td>
          <Text className="text-center">{element.age}</Text>
        </Table.Td>
        <Table.Td>
          <Text className="text-center">{element.percentForecast}</Text>
        </Table.Td>
        <Table.Td>
          <Text className="text-center">
            {dayjs(element.createdAt).format("DD-MM-YYYY / HH:mm:ss")}
          </Text>
        </Table.Td>
        <Table.Td>
          <Text className="text-center">
            {dayjs(element.updateAt).format("DD-MM-YYYY / HH:mm:ss")}
          </Text>
        </Table.Td>
        <Table.Td>
          <Flex className="text-center" justify={"center"}>
            {/* <Button variant="light" color="red" mr={10}>
              O'chirish
            </Button> */}
            <Button variant="light" onClick={() => onEdit(element.id, element)}>
              Tahrirlash
            </Button>
          </Flex>
        </Table.Td>
      </Table.Tr>
    ))
  ) : (
    <Table.Tr>
      <Table.Td colSpan={4}>
        <Text className="text-center">Xozrcha ma'lumot yo'q</Text>
      </Table.Td>
    </Table.Tr>
  );

  // Form submition
  const onSubmitForm = async (values: FormValuesForecast) => {
    try {
      setLoading(true);
      const res = id
        ? await updateForecast(values, id)
        : await createForecast(values);
      notifications.show({
        title: `Muvaffaqiyatli ${id ? "yangilandi" : "yaratildi"}`,
        message: null,
      });
      onClose();
      setTrigger((trigger) => !trigger);
    } catch (error: any) {
      notifications.show({
        title: `${error?.data?.message}`,
        message: null,
        color: "red",
      });
      console.log("🚀 ~ onSubmitForm ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  // Modal functions
  const onClose = () => {
    close();
    form.reset();
  };

  return (
    <div>
      {/* Loading */}
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      {/* Buttons */}
      {/* <Flex justify={"end"}>
        <Button onClick={open} rightSection={<Plus size={14} />}>
          Yaratish
        </Button>
      </Flex> */}

      {/* Table */}
      <Box mt={20}>
        <Table striped highlightOnHover withTableBorder withColumnBorders>
          <Table.Thead>
            <Table.Tr>
              <Table.Th className="w-[22%]">
                <Text className="font-bold text-center">Yoshi</Text>
              </Table.Th>
              <Table.Th className="w-[22%]">
                <Text className="font-bold text-center">Foiz prognozi</Text>
              </Table.Th>
              <Table.Th>
                <Text className="font-bold text-center">Yaratilgan vaqti</Text>
              </Table.Th>
              <Table.Th>
                <Text className="font-bold text-center">Yangilangan vaqti</Text>
              </Table.Th>
              {data?.length ? <Table.Th></Table.Th> : ""}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Box>

      {/* Modal */}
      <Modal size={"md"} opened={opened} onClose={onClose} title="Yaratish">
        <Box mx="auto">
          <form onSubmit={form.onSubmit((values) => onSubmitForm(values))}>
            <Flex gap={"md"}>
              <NumberInput
                label="Yoshi"
                placeholder="Iltimos, qiymatni kiriting"
                key={form.key("age")}
                {...form.getInputProps("age")}
              />
              <NumberInput
                label="Foiz prognozi"
                placeholder="Iltimos, qiymatni kiriting"
                key={form.key("percentForecast")}
                {...form.getInputProps("percentForecast")}
              />
            </Flex>

            <Group justify="flex-end" mt="md">
              <Button type="submit">{id ? "Yangilash" : "Yaratish"}</Button>
            </Group>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default Forecast;
