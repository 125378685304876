import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  LoadingOverlay,
  Modal,
  ScrollArea,
  Select,
  Table,
  Tabs,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useCalculateForecast } from "hooks/useCalculateForecast";
import { useDistrict } from "hooks/useDistrict";
import { useRegions } from "hooks/useRegions";
import { useSportsSelect } from "hooks/useSportsSelect";
import {
  CircleUserRound,
  MessageCircle,
  RotateCcw,
  TrendingUp,
} from "lucide-react";
import { getSportmenById, getTestByclass } from "modules/api";
import { useCreateSportmen } from "modules/sportmen/hooks/useCreateSportmen";
import { useEditSportmen } from "modules/sportmen/hooks/useEditSportmen";
import { useGetByIdSportmen } from "modules/sportmen/hooks/useGetByIdSportmen";
import { Forecast, Forecasted } from "modules/sportmen/types";
import { title } from "process";
import { useEffect, useState } from "react";
import { calcWeight } from "utils/calcWeight";
import { convertForecast } from "utils/convertForecast";

enum TabsType {
  gallery = "gallery",
  messages = "messages",
  settings = "settings",
}

interface Props {
  openedCreate: boolean;
  setOpenedCreate: any;
  sportmenId: number | null;
  setSportmenId: any;
}

interface Option {
  value: string;
  label: string;
}
interface Region {
  name: string;
  id: string;
}

const iconStyle = { width: rem(12), height: rem(12) };

const elements = [
  { position: 6, mass: 12.011, symbol: "C", name: "Carbon" },
  { position: 7, mass: 14.007, symbol: "N", name: "Nitrogen" },
  { position: 39, mass: 88.906, symbol: "Y", name: "Yttrium" },
  { position: 56, mass: 137.33, symbol: "Ba", name: "Barium" },
  { position: 58, mass: 140.12, symbol: "Ce", name: "Cerium" },
];

const CreateSportmenModal = (props: Props) => {
  const { openedCreate, setOpenedCreate, sportmenId, setSportmenId } = props;
  const [birthDatePicker, setBirthDatePicker] = useState<Date | null>(null);
  const [heightValue, setHeightValue] = useState<string>("");
  const [weightValue, setWeightValue] = useState<string>("");
  const [oldClassValue, setOldClassValue] = useState<string>("");
  const [classValue, setClassValue] = useState<string>("");
  const [valueRegion, setValueRegion] = useState<string | null>();
  const [valueDistrict, setValueDistrict] = useState<string | null>();
  // const { isPendingUpdate, update } = useEditSportmen();
  const [valueSport, setValueSport] = useState<string | null>();
  const { error, data, isError, isPending, status } = useCalculateForecast();
  const forecast: Forecast[] = data?.data?.data;
  const [loadingGetById, setLoadingGetById] = useState(false);
  const [forecastedData, setForecastedData] = useState<Forecasted[]>([]);
  const [isDisableClassNum, setIsDisableClassNum] = useState(false);
  const [getTestsByclass, setGetTestByclass] = useState([]);
  const { regions } = useRegions();
  const { districts, getDistrict, isPendingDis } = useDistrict();
  const { sportsSelect } = useSportsSelect();
  const { isPendingUpdate, update } = useEditSportmen(onClose, sportmenId);
  const dataReg = regions?.data?.data;
  const dataSport = sportsSelect?.data?.data;
  const { create, isPendingCreate } = useCreateSportmen(onClose);
  const [singleSport, setSingleSport] = useState<any>();
  // const { getById, isPendingById, sportmen } = useGetByIdSportmen();
  const [sportmen, setSportmen] = useState<any>();
  const [examinDtos, setExaminDtos] = useState<
    { result: string; id: number }[]
  >([]);

  const convertedData: Option[] = dataReg?.length
    ? dataReg.map((item: Region) => {
        return {
          value: item.id.toString(),
          label: item.name,
        };
      })
    : null;
  const convertedDataSport: Option[] = dataSport?.length
    ? dataSport.map((item: Region) => {
        return {
          value: item.id.toString(),
          label: item.name,
        };
      })
    : null;
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      fullName: "",
      birthDate: "",
      classNumber: "",
      heightLength: "",
      heightAge: "",
      weight: "",
      districtId: "",
      regionId: "",
      sportId: "",
      examinationDtos: [],
    },

    validate: {
      fullName: isNotEmpty(""),
      birthDate: isNotEmpty(""),
      classNumber: isNotEmpty(""),
      heightLength: isNotEmpty(""),
      heightAge: isNotEmpty(""),
      weight: isNotEmpty(""),
      sportId: isNotEmpty(""),
      regionId: isNotEmpty(""),
      // districtId: isNotEmpty(""),
    },
  });

  function onClose() {
    setSportmenId(null);
    setOpenedCreate(false);
    form.reset();
    setHeightValue("");
    setClassValue("");
    setValueDistrict("");
    setBirthDatePicker(null);
    setValueRegion(null);
    setValueSport(null);
    setWeightValue("");
    setOldClassValue("");
    setSportmen(null);
    setGetTestByclass([]);
  }

  useEffect(() => {
    form.reset();
    (async () => {
      if (sportmenId) {
        setIsDisableClassNum(true);
        try {
          setLoadingGetById(true);
          const res: any = await getSportmenById(sportmenId);
          const sportmen: any = res?.data?.data;
          setSportmen(sportmen);
          const date = dayjs(sportmen?.birthDate);
          form.setValues((prev) => ({
            ...prev,
            ...{
              fullName: sportmen?.fullName,
              birthDate: date.format("DD/MM/YYYY").replaceAll("/", "-"),
              classNumber: sportmen?.classNumber.toString(),
              heightAge: sportmen?.heightAge.toString(),
              heightLength: sportmen?.heightLength.toString(),
              weight: sportmen?.weight.toString(),
              regionId: sportmen?.regionId.toString(),
              districtId: sportmen?.districtId
                ? sportmen?.districtId.toString()
                : "",
              sportId: sportmen?.sportId ? sportmen?.sportId.toString() : "",
            },
          }));
          sportmen?.districtId && getDistrict(sportmen?.regionId.toString());
          setValueSport(sportmen?.sportId ? sportmen?.sportId.toString() : "");
          setValueRegion(sportmen?.regionId.toString());
          setValueDistrict(
            sportmen?.districtId ? sportmen?.districtId.toString() : ""
          );
          setClassValue(sportmen?.classNumber.toString());
          setWeightValue(sportmen?.weight.toString());
          setGetTestByclass(sportmen?.examinations);

          const parsedDate = sportmen?.birthDate
            ? new Date(sportmen?.birthDate)
            : "";
          parsedDate && setBirthDatePicker(parsedDate);

          setHeightValue(
            sportmen?.heightLength ? sportmen?.heightLength.toString() : ""
          );
        } catch (error: any) {
        } finally {
          setLoadingGetById(false);
        }
      } else {
        setIsDisableClassNum(false);
      }
    })();
    // getById(sportmenId);
  }, [sportmenId]);

  const handleTabs = async (e: string | null) => {
    const birthDate = birthDatePicker;
    const currentDate = dayjs();

    // getTestsByclass?.forEach((test, inx) => {
    //   form.removeListItem("examinationDtos", inx);
    // });

    // Parsing the birth date
    const parsedBirthDate = dayjs(birthDate, "DD/MM/YYYY");

    // Calculating the difference in years
    const age = currentDate.diff(parsedBirthDate, "year");

    if (e === "messages" && heightValue && age) {
      setForecastedData(
        convertForecast(forecast, Number(heightValue), Number(age))
      );
    } else {
      setForecastedData([]);
    }

    if (e === "settings" && classValue) {
      console.log("sportmen: ", sportmen);

      if (sportmen?.examinations?.length) {
        sportmen.examinations.forEach((item: any, index: any) => {
          console.log("🚀 ~ sportmen.examinations.forEach ~ item:", item);
          form.insertListItem("examinationDtos", {
            result: sportmen?.examinations?.[index]?.result
              ? sportmen.examinations[index].result
              : "",
            testId: sportmen.examinations[index].testId,
          });
        });
      }

      if (classValue !== oldClassValue && !sportmen) {
        try {
          const res = await getTestByclass({ classNumber: classValue });
          const data = res?.data?.data;
          setGetTestByclass(data);

          form.getValues().examinationDtos = [];

          if (data?.length) {
            data.forEach((item: any, index: any) => {
              form.insertListItem("examinationDtos", {
                result: sportmen ? sportmen.examinations[index].result : "",
                testId: sportmen
                  ? sportmen.examinations[index].testId
                  : item?.id,
              });
            });
          }

          setOldClassValue(classValue);
        } catch (error) {
          notifications.show({
            title: "Get exam failed",
            message: null,
            color: "red",
          });
        }
      } else {
      }
    }
  };

  // This function only for check id
  function checkExistence(property: string, value: number) {
    return getTestsByclass.some((item) => item[property] === value);
  }

  const handleChangeReg = (options: Option) => {
    if (options) {
      form.setFieldValue("regionId", options.value);
      setValueRegion(options.value);
      getDistrict(options?.value);
    }
  };
  const handleChangeDis = (options: Option) => {
    if (options) {
      form.setFieldValue("districtId", options.value);
      setValueDistrict(options.value);
    }
  };

  const handleChangeSport = (options: Option) => {
    if (options) {
      form.setFieldValue("sportId", options.value);
      setValueSport(options.value);
    }
  };

  const rows = forecastedData?.length ? (
    forecastedData.map((element, index) => (
      <Table.Tr key={index}>
        <Table.Td>
          <Text className="text-center">{element.age}</Text>
        </Table.Td>
        <Table.Td>
          <Text className="text-center">{element.height.toFixed()} (sm)</Text>
        </Table.Td>
      </Table.Tr>
    ))
  ) : (
    <Table.Tr>
      <Table.Td>No data</Table.Td>
    </Table.Tr>
  );

  const onSubmit = (data: any) => {
    const splited = data.birthDate.split("-");
    const parsedData = data?.examinationDtos?.map((item: any) => ({
      testId: item?.testId,
      result: item?.result ? Number(item.result) : "",
    }));

    const value: any = {
      fullName: data.fullName,
      birthDate: data?.birthDate
        ? `${splited[2]}-${splited[1]}-${splited[0]}`
        : "",
      classNumber: data.classNumber,
      heightLength: data.heightLength,
      heightAge: data.heightAge,
      weight: data.weight,
      districtId: data.districtId,
      regionId: data.regionId,
      sportId: data.sportId,
      examinationDtos: parsedData,
    };
    sportmenId ? update(value) : create(value);
  };

  function calculateWeight() {
    if (weightValue && heightValue) {
      const res: string = calcWeight(weightValue, heightValue);
      return res;
    } else {
      return "";
    }
  }
  return (
    <Modal.Root size={"xl"} opened={openedCreate} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Sportchi yaratish</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <LoadingOverlay
            visible={isPendingCreate}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
          <LoadingOverlay
            visible={loadingGetById}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
          <form
            onSubmit={form.onSubmit((values) => onSubmit(values))}
            className="min-h-[480px] relative"
          >
            <Flex
              className="absolute top-0 left-0 right-0 bottom-0"
              direction={"column"}
              justify={"space-between"}
            >
              <Tabs
                onChange={handleTabs}
                variant="outline"
                radius="md"
                defaultValue="gallery"
              >
                <Tabs.List grow>
                  <Tabs.Tab
                    value="gallery"
                    leftSection={<CircleUserRound style={iconStyle} />}
                  >
                    Umimiy
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="messages"
                    leftSection={<MessageCircle style={iconStyle} />}
                  >
                    Prognozlar
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="settings"
                    leftSection={<TrendingUp style={iconStyle} />}
                  >
                    Natijalar
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="gallery">
                  <Box className="pt-2">
                    <Grid>
                      <Grid.Col span={12}>
                        <TextInput
                          label="To'liq ism"
                          placeholder="Iltimos, toʻliq ismini kiriting"
                          {...form.getInputProps("fullName")}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                        <DatePickerInput
                          label="Tug'ilgan kun sanasi"
                          rightSection={
                            <RotateCcw
                              size={14}
                              className="cursor-pointer"
                              onClick={() => {
                                console.log(form.values);
                                // form.errors.birthDate = true;
                                // form.clearFieldError("birthDate");
                                form.setFieldValue("birthDate", "");
                                setBirthDatePicker(null);
                              }}
                            />
                          }
                          placeholder="Iltimos, tug'ilgan sanani kiriting"
                          {...(form.getInputProps("birthDate"),
                          {
                            value: birthDatePicker,
                            onChange: (e) => {
                              form.setFieldValue(
                                "birthDate",
                                `${dayjs(e)
                                  .format("DD/MM/YYYY")
                                  .replaceAll("/", "-")}`
                              );
                              // form.errors.birthDate = false;
                              setBirthDatePicker(e);
                              // form.setFieldValue("birthDate", String(e));
                              form.setFieldError("birthDate", "");
                            },
                            error: form.errors.birthDate,
                          })}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                        <TextInput
                          type="number"
                          // allowNegative={false}
                          // min={0}
                          // max={11}
                          // clampBehavior="strict"
                          label="Sinf raqami"
                          disabled={isDisableClassNum}
                          placeholder="Iltimos, sinf raqamini kiriting"
                          {...(form.getInputProps("classNumber"),
                          {
                            value: classValue,
                            onChange: (event) => {
                              const e = event.target.value;
                              form.setFieldError("classNumber", "");
                              form.setFieldValue("classNumber", String(e));
                              setClassValue(String(e));
                            },
                            error: form.errors.classNumber,
                          })}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                        <TextInput
                          // allowNegative={false}
                          // min={0}
                          // max={999}
                          // clampBehavior="strict"
                          type="number"
                          label="Uzunlik uzunligi (sm)"
                          placeholder="Iltimos, balandlik uzunligini kiriting"
                          {...(form.getInputProps("heightLength"),
                          {
                            value: heightValue,
                            onChange: (event) => {
                              const e = event.target.value;
                              form.setFieldError("heightLength", "");
                              // form.errors.heightLength = "";
                              form.setFieldValue("heightLength", String(e));
                              setHeightValue(String(e));
                            },
                            error: form.errors.heightLength,
                          })}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                        <TextInput
                          type="number"
                          // allowNegative={false}
                          // min={0}
                          // max={99}
                          // clampBehavior="strict"
                          label="Balandlik yoshi"
                          placeholder="Iltimos, bo'y yoshini kiriting"
                          {...form.getInputProps("heightAge")}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                        <TextInput
                          // allowNegative={false}
                          // min={0}
                          // max={9999}
                          // clampBehavior="strict"
                          type="number"
                          label="Og'irligi"
                          placeholder="Iltimos, vaznni kiriting"
                          {...(form.getInputProps("weight"),
                          {
                            value: weightValue,
                            onChange: (event) => {
                              const e = event.target.value;
                              form.setFieldError("weight", "");
                              form.setFieldValue("weight", String(e));
                              setWeightValue(String(e));
                            },
                            error: form.errors.weight,
                          })}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                        {/* <TextInput
                          label="Sport"
                          placeholder="Please enter sport"
                          {...form.getInputProps("sportId")}
                        /> */}
                        <Select
                          {...(form.getInputProps("sportId"),
                          {
                            onChange: (newValue, options) =>
                              handleChangeSport(options),
                            error: form.errors.sportId,
                            value: valueSport,
                          })}
                          label={"Sport"}
                          placeholder="Iltimos, sportni tanlang"
                          data={convertedDataSport}
                        />
                      </Grid.Col>

                      <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                        <Select
                          {...(form.getInputProps("regionId"),
                          {
                            onChange: (newValue, options) =>
                              handleChangeReg(options),
                            error: form.errors.regionId,
                            value: valueRegion,
                          })}
                          label={"Viloyat"}
                          placeholder="Iltimos, viloyatni tanlang"
                          data={convertedData}
                          // value={value}
                          // onChange={(newValue, options) => handleChangeReg(options)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                        <Select
                          {...(form.getInputProps("districtId"),
                          {
                            onChange: (newValue, options) =>
                              handleChangeDis(options),
                            error: form.errors.districtId,
                            value: valueDistrict,
                          })}
                          label={"Tuman"}
                          placeholder="Iltimos, tumanni tanlang"
                          data={districts}
                          // value={value}
                          // onChange={(newValue, options) => handleChangeDis(options)}
                        />
                      </Grid.Col>
                    </Grid>
                  </Box>
                </Tabs.Panel>

                <Tabs.Panel value="messages">
                  <Box className="pt-16">
                    {weightValue ? (
                      <Flex justify={"center"}>
                        {/* <span>Vazni haqida ma'lumot:</span> */}
                        <Badge>{calculateWeight()}</Badge>
                        {}
                      </Flex>
                    ) : (
                      ""
                    )}
                    {forecastedData?.length ? (
                      <ScrollArea h={300} type="auto" offsetScrollbars>
                        <Table
                          mt={20}
                          withColumnBorders
                          striped
                          withTableBorder
                        >
                          <Table.Thead>
                            <Table.Tr>
                              <Table.Th>
                                <Text className="text-center">Yosh</Text>
                              </Table.Th>
                              <Table.Th>
                                <Text className="text-center">Bo'yi</Text>
                              </Table.Th>
                            </Table.Tr>
                          </Table.Thead>
                          <Table.Tbody>{rows}</Table.Tbody>
                        </Table>
                      </ScrollArea>
                    ) : (
                      ""
                    )}

                    {/* <AreaChart
                      h={300}
                      data={dataChart}
                      dataKey="date"
                      series={[
                        { name: "Apples", color: "indigo.6" },
                        { name: "Oranges", color: "blue.6" },
                        { name: "Tomatoes", color: "teal.6" },
                      ]}
                      curveType="linear"
                      gridAxis="y"
                      withYAxis={false}
                    /> */}
                    {/* <Text className="absoluteTransform">No result</Text> */}
                  </Box>
                </Tabs.Panel>

                <Tabs.Panel value="settings">
                  <Box className="pt-4">
                    <Flex justify={"center"}>
                      <Text pb={5}>Umumiy testlar</Text>
                    </Flex>
                    <Divider mb={20} />
                    <Grid>
                      {getTestsByclass?.length
                        ? getTestsByclass.map(
                            (test: any, inx: number) =>
                              checkExistence("id", test?.id) && (
                                <Grid.Col
                                  key={test?.id}
                                  span={{ base: 6, md: 6, lg: 6 }}
                                >
                                  <TextInput
                                    type="number"
                                    label={test?.name}
                                    placeholder="Iltimos, qiymatni kiriting"
                                    {...(form.getInputProps(
                                      `examinationDtos.${inx}.result`
                                    ),
                                    {
                                      onChange: (e) =>
                                        form.setFieldValue(
                                          `examinationDtos.${inx}.result`,
                                          e.target.value
                                        ),
                                      value: form.getValues().examinationDtos?.[
                                        inx
                                      ]?.["result"]
                                        ? form.getValues().examinationDtos?.[
                                            inx
                                          ]?.["result"]
                                        : "",
                                    })}
                                  />
                                </Grid.Col>
                              )
                          )
                        : ""}
                    </Grid>
                  </Box>
                </Tabs.Panel>
              </Tabs>
              <Flex justify={"end"} mt={20}>
                <Box>
                  <Button variant="default" mr={10} onClick={onClose}>
                    Yopish
                  </Button>
                  <Button className="mx-2" type="submit">
                    {sportmenId ? "Yangilash" : "Yaratish"}
                  </Button>
                </Box>
              </Flex>
            </Flex>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default CreateSportmenModal;
