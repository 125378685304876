export function calcWeight(weight: any, height: any) {
  const weightStandard = (weight / (height * height)) * 10000;

  if (weightStandard < 19) {
    return "Tana vazni yetishmovchiligi";
  } else if (weightStandard < 19.9) {
    return "Tana vazni kamligi";
  } else if (weightStandard < 25.9) {
    return "Tana vazni me'yorida";
  } else if (weightStandard < 27.9) {
    return "Tana vazni ortiqcha";
  } else if (weightStandard < 30.9) {
    return "Birinchi darajali semizlik";
  } else if (weightStandard < 35.9) {
    return "Ikkinchi darajali semizlik";
  } else if (weightStandard < 40.9) {
    return "Uchinchi darajali semizlik";
  } else {
    return "To'rtinchi darajali semizlik";
  }
}
