import React from "react";

const MyError = ({ error }: { error: string }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        // transform: "translate(-50%, -50%)",
      }}
    >
      <h1>{error}</h1>
    </div>
  );
};

export default MyError;
