import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { getForecast } from "modules/api";

export function useCalculateForecast() {
  const result: UseQueryResult<AxiosResponse, Error> = useQuery({
    queryKey: ["forecast"],
    queryFn: getForecast,
    retry: false,
  });

  const { isFetching: isPending, data, error, isError, status } = result;

  return { status, isError, isPending, error, data };
}
