import { Button, Flex, Table, Text } from "@mantine/core";
import dayjs from "dayjs";
import { SportMen } from "modules/sportmen/types";

interface Props {
  data: SportMen[];
  openEditModal: any;
  openDeleteModal: any;
}

const TableSportmen = (props: Props) => {
  const { data, openEditModal, openDeleteModal } = props;

  const rows = data?.length ? (
    data.map((element, index: number) => (
      <Table.Tr key={index}>
        <Table.Td>
          <Text className="text-center">{index + 1}</Text>
        </Table.Td>
        <Table.Td>
          <Text className="text-center">{element.fullName}</Text>
        </Table.Td>
        <Table.Td>
          <Text className="text-center">
            {dayjs(element.createdAt).format("DD/MM/YYYY")}
          </Text>
        </Table.Td>
        <Table.Td>
          <Text className="text-center">{element.regionName}</Text>
        </Table.Td>
        <Table.Td>
          <Text className="text-center">{element.districtName}</Text>
        </Table.Td>
        <Table.Td>
          <Text className="text-center">{element.sportName}</Text>
        </Table.Td>
        <Table.Td>
          <Text className="text-center">{element.classNumber}</Text>
        </Table.Td>
        <Table.Td>
          <Flex className="text-center" justify={"center"}>
            <Button
              variant="light"
              color="red"
              mr={10}
              onClick={() => openDeleteModal(element.id)}
            >
              O'chirish
            </Button>
            <Button onClick={() => openEditModal(element.id)}>
              Tahrirlash
            </Button>
          </Flex>
        </Table.Td>
      </Table.Tr>
    ))
  ) : (
    <Table.Tr>
      <Table.Td colSpan={8}>
        <Text className="text-center">No data</Text>
      </Table.Td>
    </Table.Tr>
  );
  return (
    <Table
      mt={20}
      // stickyHeaderOffset={60}
      withColumnBorders
      // horizontalSpacing={"md"}
      striped
      // highlightOnHover
      withTableBorder
    >
      <Table.Thead>
        <Table.Tr>
          <Table.Th className="w-16">
            <Text className="text-center">№</Text>
          </Table.Th>
          <Table.Th className="w-96">
            <Text className="text-center">F.I.O.</Text>
          </Table.Th>
          <Table.Th className="w-52">
            <Text className="text-center">Yaratilgan vaqti</Text>
          </Table.Th>
          <Table.Th>
            <Text className="text-center">Viloyati</Text>
          </Table.Th>
          <Table.Th>
            <Text className="text-center">Tumani</Text>
          </Table.Th>
          <Table.Th>
            <Text className="text-center">Sport turi</Text>
          </Table.Th>
          <Table.Th>
            <Text className="text-center">Sinfi</Text>
          </Table.Th>
          <Table.Th>
            <Text className="text-center">Actions</Text>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
      {/* <Table.Caption>Scroll page to see sticky thead</Table.Caption> */}
    </Table>
  );
};

export default TableSportmen;
