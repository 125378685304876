import { useMutation, useQuery } from "@tanstack/react-query";
import { getSports } from "modules/api";
import { ResponseSports, Sports, SportsParams } from "../types";
import { useState } from "react";
import { AxiosResponse } from "axios";
import { useUpdateAccess } from "hooks/useUpdateAccess";
import { clearSession } from "services/store";

export function useSports(params: SportsParams) {
  const [sports, setSports] = useState<ResponseSports>();
  const [error, setError] = useState<string>("");
  const { status, updateToken } = useUpdateAccess();

  const { mutate: fetchSports, isPending: isLoading } = useMutation({
    mutationFn: (params: SportsParams) => {
      console.log("🚀 ~ useSports ~ params:", params);
      return getSports(params);
    },
    retry: false,
    onSuccess: (res: AxiosResponse) => {
      setError("");
      const data = res?.data?.data;
      setSports(data);
      console.log("🚀 ~ useSports ~ data:", data);
    },
    onError: (e: any) => {
      setError(`${e?.data?.message}`);
      const statusCode: number = e?.status;
      // statusCode === 401 && clearSession();
    },
  });

  return { isLoading, fetchSports, sports, error };
}
