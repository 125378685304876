import { useQuery } from "@tanstack/react-query";
import { getRegions } from "modules/api";

export function useRegions() {
  const {
    isPending,
    data: regions,
    error,
    isError,
    status,
  } = useQuery({
    queryKey: ["regions"],
    queryFn: getRegions,
    retry: false,
  });

  return { status, isError, isPending, error, regions };
}
