import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { getArticles, getSports } from "modules/api";
import { useState } from "react";
import { ResponseSports, SportsParams } from "../types";

export function useSports(params: SportsParams) {
  const [sports, setSports] = useState<ResponseSports>();
  const [error, setError] = useState<string>("");

  const { mutate: fetchSports, isPending: isLoading } = useMutation({
    mutationFn: (params: SportsParams) => {
      return getArticles(params);
    },
    retry: false,
    onSuccess: (res: AxiosResponse) => {
      setError("");
      const data = res?.data?.data;
      setSports(data);
    },
    onError: (e: any) => {
      console.log("🚀 ~ useSports ~ eeeeee:", e);
      setError(`${e?.data?.message}`);
      const statusCode: number = e?.status;
      // statusCode === 401 && clearSession();
    },
  });

  return { isLoading, fetchSports, sports, error };
}
