import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  Modal,
  Pagination,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  Textarea,
  rem,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import {
  Dropzone,
  FileRejection,
  FileWithPath,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import MyModal from "components/Modal";
import MyError from "components/MyError";
import TableSports from "components/TableSports";
import config from "config";
import {
  ImageIcon,
  Plus,
  RotateCcw,
  ScanSearch,
  Search,
  Upload,
  X,
} from "lucide-react";
import { deleteSport, getSportById } from "modules/api";
import { useCreateSport } from "modules/sports/hooks/useCreateSport";
import { useEditSport } from "modules/sports/hooks/useEditSport";
import { useSports } from "modules/sports/hooks/useSports";
import {
  SingleSport,
  Sports as SportType,
  SportsParams,
} from "modules/sports/types";
import React, { useEffect, useState } from "react";
import { formatFileSize } from "utils/FormatFileSize";

interface Values {
  type: string;
  name: string;
  shortDescription: string;
  description: string;
  //   photo: string;
}

interface RequestType {
  name: string;
  shortDescription: string;
  description: string;
  photo?: FileWithPath;
}

const Sports = () => {
  const queryClient = useQueryClient();

  const [params, setParams] = useState<SportsParams>({
    from: null,
    to: null,
    search: null,
    page: 1,
    size: null,
  });
  const [createEditModal, setCreateEditModal] = useState(false);
  const [modalType, setModalType] = useState<"create" | "edit">("create");
  const [sportType, setSportType] = useState("");
  const [sportId, setSportId] = useState<number | null>(null);
  const {
    isSuccess,
    create,
    isLoading: isLoadingCreate,
  } = useCreateSport({ refetch: handleReset });

  const {
    isSuccess: isSuccessUpdate,
    update,
    isLoading: isLoadingUpdate,
  } = useEditSport({ refetch: handleReset, id: sportId });
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [singleSportImg, setSingleSportImg] = useState<string | null>(null);
  const [singleSportImgPath, setSingleSportImgPath] = useState<string | null>(
    null
  );
  const form = useForm({
    mode: "controlled",
    initialValues: {
      from: "",
      to: "",
      search: "",
    },

    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });
  const formCreateSport = useForm({
    mode: "uncontrolled",
    initialValues: {
      name: "",
      type: "",
      shortDescription: "",
      description: "",
    },

    validate: {
      name: isNotEmpty(""),
      type: isNotEmpty(""),
      shortDescription: isNotEmpty(""),
      description: isNotEmpty(""),
    },
  });
  const [loadingEdit, setLoadingEdit] = useState(false);
  const { error, fetchSports, isLoading, sports } = useSports(params);

  useEffect(() => {
    fetchSports(params);

    console.log("🚀 ~ Sports ~ sports:", sports);
  }, [params]);

  // useEffect(() => {
  //   if (isSuccess) {
  //     handleReset();
  //     onClose();
  //   }
  // }, [isSuccess]);

  // useEffect(() => {
  //   if (isSuccessUpdate) {
  //     handleReset();
  //     onClose();
  //   }
  // }, [isSuccessUpdate]);

  function handleReset() {
    formCreateSport.reset();
    setParams({
      from: null,
      to: null,
      search: null,
      page: 1,
      size: null,
    });
    // queryClient.invalidateQueries({ queryKey: ["sports"] });
    fetchSports(params);
    setFile(null);
    onClose();
  }

  function handleResetForm() {
    form.reset();
    console.log("🚀 ~ handleResetForm ~ form:", form);

    setParams({
      from: null,
      to: null,
      search: null,
      page: 1,
      size: null,
    });
    fetchSports(params);
  }

  const reFetch = () => {
    fetchSports(params);
  };

  const onClose: () => void = () => {
    formCreateSport.reset();
    setFile(null);
    setSingleSportImg(null);
    setCreateEditModal((prevent) => !prevent);
    setSportType("");
  };

  const handlePage = (e: any) => {
    setParams((prev) => ({
      ...prev,
      page: e,
    }));
    console.log("🚀 ~ handlePage ~ e:", e);
  };

  const onSubmit = (e: { from: string; to: string; search: string }) => {
    const { from, to, search } = e;

    setParams((prev) => ({
      ...prev,
      ...(from && { from }),
      ...(to && { to }),
      ...(search && { search }),
    }));
  };

  const handleCreate = () => {
    setCreateEditModal(true);
    setModalType("create");
  };

  async function getSingleSport(id: number) {
    try {
      setLoadingEdit(true);
      setCreateEditModal(true);
      const res = await getSportById(id);
      const data: SingleSport = res?.data?.data;
      formCreateSport.setValues({
        type: data?.type ? data.type : "",
        name: data.name,
        description: data.description,
        shortDescription: data.shortDescription,
      });
      setSportType(data?.type ? data.type : "");
      setSportId(data.id);
      setSingleSportImg(data.photo);
      setSingleSportImgPath(data.photo);
      console.log("🚀 ~ getSingleSport ~ res:", res?.data?.data);
    } catch (error: any) {
      notifications.show({
        title: `${error?.data?.message}`,
        message: null,
        color: "red",
      });
      console.log("🚀 ~ getSingleSport ~ error:", error);
    } finally {
      setLoadingEdit(false);
    }
  }

  const handleEdit: (id: number) => void = (id: number) => {
    console.log("Handle edit");
    getSingleSport(id);
    // setSportId(id);
    // setCreateEditModal(true);
    setModalType("edit");
  };

  const handleDelete: (id: number) => void = (id: number) => {
    modals.openConfirmModal({
      title: "Iltimos, harakatingizni tasdiqlang",
      children: (
        <Text size="sm">Ushbu sport turini o'chirib tashlamoqchimisiz?</Text>
      ),
      labels: { confirm: "Xa", cancel: "Yo'q" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => deleteSportFunc(id),
    });
  };

  const deleteSportFunc = async (id: number | null) => {
    try {
      const res = await deleteSport(id);
      notifications.show({
        title: `Muvaffaqiyatli oʻchirildi`,
        message: null,
      });
      setParams({
        from: null,
        to: null,
        search: null,
        page: 1,
        size: null,
      });
      fetchSports(params);
    } catch (error) {
      notifications.show({
        title: `Oʻchirishda xato yuz berdi`,
        message: null,
        color: "red",
      });
      console.log("🚀 ~ deleteSport ~ error:", error);
    }
  };

  const deletePhoto = () => {
    setFile(null);
    setSingleSportImg(null);
  };
  const preview = file ? (
    <Box>
      <Image
        src={URL.createObjectURL(file)}
        onLoad={() => URL.revokeObjectURL(URL.createObjectURL(file))}
      />
      <Button onClick={deletePhoto} fullWidth mt={10} variant="default">
        O'chirish
      </Button>
    </Box>
  ) : singleSportImg ? (
    <Box>
      <Image src={`${config.api.baseURL}/storage/files/${singleSportImg}`} />
      <Button onClick={deletePhoto} fullWidth mt={10} variant="default">
        O'chirish
      </Button>
    </Box>
  ) : null;

  const onDrop = (file: FileWithPath[]) => {
    console.log("🚀 ~ onDrop ~ file:", file);
    setFile(file[0]);
  };

  const onReject = (file: FileRejection[]) => {
    const fileSizeInBytes: number = file[0]?.file.size;
    const formattedSize: string = formatFileSize(fileSizeInBytes);

    // File is larger than 10485760 bytes
    notifications.show({
      title: `File is larger than ${formattedSize}`,
      message: "Can upload up to 10mb",
      color: "red",
    });
  };

  const onSubmitCreateSport = (values: Values) => {
    console.log("🚀 ~ onSubmitCreateSport ~ values:", values);
    const data: any = {
      type: sportType,
      name: values.name,
      description: values.description,
      shortDescription: values.shortDescription,
      ...(file && { photo: file }),
      ...(singleSportImg === null && {
        deletePhotoPath: singleSportImgPath,
      }),
    };

    modalType === "create" ? create(data) : update(data);
  };

  if (error) return <MyError error={error} />;
  return (
    <div>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Text size="xl">Filtrlar</Text>
      <Flex align={"end"} justify={"space-between"}>
        <Flex align={"end"}>
          <form
            className="flex items-end"
            onSubmit={form.onSubmit((values) => onSubmit(values))}
          >
            <DateInput
              // value={value}
              // onChange={setValue}
              className="mr-2"
              {...form.getInputProps("from")}
              label="Qachondan"
              placeholder="Sanani kiriting"
            />
            <DateInput
              // value={value}
              // onChange={setValue}
              className="mr-2"
              {...form.getInputProps("to")}
              label="Qachongacha"
              placeholder="Sanani kiriting"
            />
            <TextInput
              label="Qidirish"
              placeholder="Iltimos, qidiruv so'zini kiriting"
              {...form.getInputProps("search")}
            />
            <Button
              className="mx-2"
              type="submit"
              rightSection={<Search size={14} />}
            >
              Qidirish
            </Button>
            <ActionIcon
              onClick={handleResetForm}
              size={"input-sm"}
              variant="filled"
              aria-label="Settings"
            >
              <RotateCcw size={14} />
            </ActionIcon>
          </form>
        </Flex>
        <Box>
          <Button rightSection={<Plus size={14} />} onClick={handleCreate}>
            Yaratish
          </Button>
        </Box>
      </Flex>

      <Box className="mt-4">
        <TableSports
          sports={sports?.items}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
        {sports?.meta && (
          <Flex justify={"center"} mt={20}>
            <Pagination
              onChange={handlePage}
              value={sports.meta.page}
              total={sports.meta.totalPage}
            />
          </Flex>
        )}
      </Box>

      <Modal.Root size={"xl"} opened={createEditModal} onClose={onClose}>
        <LoadingOverlay
          visible={loadingEdit}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              {modalType === "create" ? "Sport yaratish" : "Sportni tahrirlash"}
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={formCreateSport.onSubmit((values) =>
                onSubmitCreateSport(values)
              )}
            >
              <Flex
                gap="md"
                justify="flex-start"
                align="flex-start"
                wrap="wrap"
                direction={"column"}
              >
                <TextInput
                  className="w-full"
                  label="Sport nomi"
                  placeholder="Iltimos, sport nomini kiriting"
                  {...formCreateSport.getInputProps("name")}
                />
                <Select
                  className="w-full"
                  label="Sport turini tanlang"
                  placeholder="Iltimos, sport turini tanlang"
                  data={[
                    { value: "SPORT_OYINLARI", label: "Sport o'yinlari" },
                    {
                      value: "YAKKA_KURASH_SPORT_TURLARI",
                      label: "Yakka kurash sport turlari",
                    },
                    {
                      value: "QIYIN_MUVOFIQLASHTIRUVCHI_SPORT_TURLARI",
                      label: "Qiyin muvofiqlashtiruvchi sport turlari",
                    },
                    {
                      value: "KUCHLI_SPORT_TURLARI",
                      label: "Kuchli sport turlari",
                    },
                    {
                      value: "SIKLIK_SPORT_TURLARI",
                      label: "Siklik sport turlari",
                    },
                  ]}
                  {...(formCreateSport.getInputProps("type"),
                  {
                    value: sportType,
                    onChange: (e: any) => {
                      console.log("🚀 ~ Sports ~ e:", e);
                      setSportType(e);
                      formCreateSport.setFieldValue("type", e);
                      formCreateSport.errors.type = "";
                    },
                    error: formCreateSport.errors.type,
                  })}
                />
                <Textarea
                  autosize
                  minRows={2}
                  maxRows={3}
                  maxLength={1000}
                  className="w-full"
                  label="Qisqa ma'lumot"
                  placeholder="Iltimos, qisqa ma'lumot kiriting"
                  {...formCreateSport.getInputProps("shortDescription")}
                />
                <Textarea
                  autosize
                  minRows={2}
                  maxRows={3}
                  maxLength={20000}
                  className="w-full"
                  label="Izoh"
                  placeholder="Iltimos, izoh kiriting"
                  {...formCreateSport.getInputProps("description")}
                />

                <Box className="w-full">
                  <Text size="sm">Sport rasmi</Text>
                  <Dropzone
                    className="w-full"
                    onDrop={(file) => onDrop(file)}
                    onReject={(file) => onReject(file)}
                    maxSize={10 * 1024 ** 2}
                    accept={IMAGE_MIME_TYPE}
                    // {...props}
                  >
                    <Group
                      justify="center"
                      gap="sm"
                      mih={20}
                      style={{ pointerEvents: "none" }}
                    >
                      <Dropzone.Accept>
                        <Upload
                          style={{
                            width: rem(52),
                            height: rem(52),
                            color: "var(--mantine-color-blue-6)",
                          }}
                          //   stroke={1.5}
                        />
                      </Dropzone.Accept>
                      <Dropzone.Reject>
                        <X
                          style={{
                            width: rem(52),
                            height: rem(52),
                            color: "var(--mantine-color-red-6)",
                          }}
                          //   stroke={1.5}
                        />
                      </Dropzone.Reject>
                      <Dropzone.Idle>
                        <ImageIcon
                          style={{
                            width: rem(42),
                            height: rem(42),
                            color: "var(--mantine-color-dimmed)",
                          }}
                          //   stroke={1.5}
                        />
                      </Dropzone.Idle>

                      <div>
                        <Text size="lg" inline>
                          Rasmni bu yerga torting yoki rasm tanlash uchun bosing
                        </Text>
                        <Text size="sm" c="dimmed" inline mt={7}>
                          Bitta rasm yuklang, rasm 5-mb dan oshmasligi kerak
                        </Text>
                      </div>
                    </Group>
                  </Dropzone>
                  <SimpleGrid cols={{ base: 1, sm: 4 }} mt={preview ? "xl" : 0}>
                    {preview}
                  </SimpleGrid>
                </Box>
              </Flex>
              <Flex justify={"end"} mt={20}>
                <Box>
                  <Button variant="default" mr={10} onClick={onClose}>
                    Yopish
                  </Button>
                  <Button
                    loading={isLoadingCreate}
                    className="mx-2"
                    type="submit"
                  >
                    {modalType === "create" ? "Yaratish" : "Yangilash"}
                  </Button>
                </Box>
              </Flex>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      {/* <MyModal
        singlesport={singleSport}
        sportid={sportId}
        type={modalType}
        refetch={reFetch}
        onClose={onClose}
        createEditModal={createEditModal}
      /> */}
    </div>
  );
};

export default Sports;
